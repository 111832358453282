import React from 'react';
import { Box, Card, Dialog, Typography, styled } from '@mui/material';
import Header from '../../components/layouts/headers/Headers';
import Sidebar from '../../components/layouts/sidebar/Sidebar';
import { Outlet, useNavigate } from 'react-router-dom';
import HamSidebar from '../../components/layouts/sidebar/HamSidebar';
import { logOut } from '../../components/app/features/auth/AuthSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthSelector } from '../../components/utils/selectors/selectors';

const DashboardPageWrapper = styled(Box)(
   ({ theme }) => `
       min-height: 100vh;
       @media (max-width: 1120px) {
            margin-left: 0;
            width: 100%;
        }
     
`
);

const Dashboard = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const selector = useSelector(getAuthSelector);
   const [isOpen, setIsOpen] = React.useState(true);
   const [mobileOpen, setMobileOpen] = React.useState(false);
   const [logOff, setLogOff] = React.useState(false);
   // const [messageCount, setMessageCount] = React.useState(0);

   const getHamState = (state) => {
      setIsOpen(state);
   };

   const getMobileHamState = (state) => {
      setMobileOpen(state);
   };

   React.useEffect(() => {
      if (selector.logOut === true) {
         navigate('/login');
         sessionStorage.clear();
         localStorage.clear();
      }
   }, [selector.logOut === true]);

   const getLogOutState = (state) => setLogOff(state);

   return (
      <Box>
         <Header
            isOpen={isOpen}
            mobileOpen={mobileOpen}
            getHamState={getHamState}
            getMobileHamState={getMobileHamState}
            getLogOutState={getLogOutState}
         />
         <Sidebar isOpen={isOpen} getLogOutState={getLogOutState} />
         <HamSidebar
            openMenu={mobileOpen}
            getHamState={getMobileHamState}
            getLogOutState={getLogOutState}
         />
         <DashboardPageWrapper
            sx={{
               marginLeft: isOpen ? '250px' : '100px',
            }}
         >
            <Outlet />
         </DashboardPageWrapper>
         <Dialog
            onClose={() => {
               setLogOff(false);
            }}
            open={logOff}
         >
            <Card sx={{ padding: '30px 50px ' }}>
               <Typography
                  sx={{
                     fontWeight: '600',
                     fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                     textAlign: 'center',
                     marginBottom: '2rem',
                  }}
               >
                  Are you sure you want to log out?
               </Typography>
               <Box
                  sx={{
                     display: 'flex',
                     justifyContent: 'center',
                  }}
               >
                  <Typography
                     sx={{
                        fontWeight: '600',
                        fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                        cursor: 'pointer',
                        marginRight: ' 1rem',
                     }}
                     onClick={() => {
                        setLogOff(false);
                     }}
                  >
                     Cancel
                  </Typography>
                  <Typography
                     sx={{
                        fontWeight: '600',
                        fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                        color: 'red',
                        cursor: 'pointer',
                     }}
                     onClick={() => {
                        dispatch(logOut());
                     }}
                  >
                     Yes
                  </Typography>
               </Box>
            </Card>
         </Dialog>
      </Box>
   );
};

export default Dashboard;
