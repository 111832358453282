import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import store from './components/app/store/store';
import theme from './components/utils/theme/theme';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from '@mui/material/styles';

ReactDOM.createRoot(document.getElementById('root')).render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<Provider store={store}>
				<App />
				<ToastContainer />
			</Provider>
		</ThemeProvider>
	</React.StrictMode>
);
