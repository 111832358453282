import React from 'react';
import {
   Box,
   Button,
   Card,
   TextField,
   TextareaAutosize,
   Typography,
} from '@mui/material';
import backImg from '../../assets/back.svg';
import twitter from '../../assets/twitter.svg';
import linkedin from '../../assets/linkedin.svg';
import facebook from '../../assets/facebook.svg';
import instagram from '../../assets/instagram.svg';
import styled from '@emotion/styled';
import { Formik } from 'formik';
import * as Yup from 'yup';

export const Container = styled(Box)(
   ({ theme }) => `
 padding:  ${theme.header.height} 0 2rem 0; 
 min-height: 100vh; 
 background: #E8E8E8;
 `
);

const TextInput = styled(TextField)(
   ({ theme }) => `
       background: white;
       color: #49454F;
       &.placeholer{
        color: red;
       }
     `
);

const DescBox = styled(TextareaAutosize)(
   ({ theme }) => `
       background: white;
       color: #49454F;
       width:100%;
       margin-top: 5px;
       border:1px solid #374DA0;
       min-height:150px;
       padding:15px;
       border-radius:5px ;
							font-size: 1rem;
       &.placeholer{
        color: red;
       }
       &:focus{

        outline: 1px solid #374DA0;
       }
     `
);

export const FlatButton = styled(Button)(
   ({ theme }) => `
background: #374DA0;
color: #fff;
font-weight: 600;
width: 60%;
text-align: center;
font-size: 0.9rem;
border-radius:10px;
margin: 1rem auto;

&:hover {background: #374DA0;}
`
);

const Contact = () => {
   return (
      <Container>
         <Card
            sx={{
               width: '90%',
               maxWidth: '615px',
               minHeight: '80vh',
               margin: '0 auto',
               marginTop: '2rem',
               position: 'relative',
               boxShadow:
                  '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
               padding: '20px ',
               background: '#E8E8E8',
            }}
         >
            <Box
               sx={{
                  margin: '0 auto',
                  marginTop: '1rem',
                  border: '1px solid transparent',
                  position: 'relative',
               }}
            >
               <img
                  src={backImg}
                  alt="back"
                  style={{
                     position: 'absolute',
                     width: '40px',
                     cursor: 'pointer',
                  }}
                  onClick={() => window.history.back()}
               />
               <Typography
                  sx={{
                     width: 'max-content',
                     margin: '0 auto',
                     fontWeight: 600,
                     fontSize: 'clamp(1.1rem, 2vw, 1.25rem)',
                     color: '#0F0E0E',

                     marginTop: '1rem',
                  }}
               >
                  Contact us
               </Typography>
               <Typography
                  sx={{
                     // width: 'max-content',
                     fontWeight: 600,
                     fontSize: '1rem',
                     color: '#0F0E0E',
                     fontFamily: "'Raleway', 'Montserrat', sans-serif",
                     textAlign: 'center',
                     mt: 2,
                  }}
               >
                  Feel free to reach out to us through our social handles or by
                  filling out the Contact form below. We value your feedback and
                  look forward to serving you!
               </Typography>
               <Typography
                  sx={{
                     my: 4,
                     width: '150px',
                     mx: 'auto',
                     display: 'flex',
                     justifyContent: 'space-between',
                  }}
               >
                  <a
                     href="https://www.linkedin.com/in/parrotica-ng-aa8779289?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                     target="_blank"
                  >
                     <img
                        style={{
                           width: '30px',
                        }}
                        src={linkedin}
                        alt="icon"
                     />
                  </a>
                  <a
                     href="https://instagram.com/parrotica_ng?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D"
                     target="_blank"
                  >
                     <img
                        style={{
                           width: '30px',
                        }}
                        src={instagram}
                        alt="icon"
                     />
                  </a>
                  <a href="https://twitter.com/parrotica" target="_blank">
                     <img
                        style={{
                           width: '30px',
                        }}
                        src={twitter}
                        alt="icon"
                     />
                  </a>
                  <a
                     href="https://www.facebook.com/profile.php?id=100084230776114&mibextid=9R9pXO"
                     target="_blank"
                  >
                     <img
                        style={{
                           width: '30px',
                        }}
                        src={facebook}
                        alt="icon"
                     />
                  </a>
               </Typography>
               <Formik
                  initialValues={{
                     email: '',
                     feature: '',
                     desc: '',
                  }}
                  validationSchema={Yup.object().shape({
                     email: Yup.string().required('Please enter your email'),
                     feature: Yup.string().required(
                        'Please enter Feature Name'
                     ),
                     desc: Yup.string()
                        .label('desc')
                        .required('Please enter description'),
                  })}
                  onSubmit={async (values, { setSubmitting }) => {
                     setTimeout(() => {
                        setSubmitting(false);
                     }, 400);
                  }}
               >
                  {({
                     errors,
                     handleBlur,
                     handleChange,
                     handleSubmit,
                     isSubmitting,
                     touched,
                     values,
                     setFieldValue,
                  }) => (
                     <form action="https://formsubmit.co/support@parrotica.com" method="POST"
                        noValidate
                        style={{
                           marginTop: '2rem',
                        }}
                     >
                        <Typography
                           sx={{
                              lineHeight: 1,
                              fontWeight: 600,
                              fontFamily: "'Raleway', 'Montserrat', sans-serif",
                              fontSize: '0.9rem',
                              mb: '5px',
                           }}
                        >
                           Email
                        </Typography>
                        <TextInput
                           // error={Boolean(touched.name && errors.name)}
                           fullWidth
                           margin="normal"
                           name="email"
                           placeholder="Enter your email"
                           onBlur={handleBlur}
                           onChange={handleChange}
                           type="email"
                           value={values.email}
                           variant="outlined"
                           // InputProps={
                           // 	{
                           // 		// startAdornment: (
                           // 		// 	<InputAdornment position='start'>
                           // 		// 		<img src={contactImg} alt='contact' />
                           // 		// 	</InputAdornment>
                           // 		// ),
                           // 		// endAdornment: (
                           // 		// 	<InputAdornment position='end'>
                           // 		// 		<IconButton
                           // 		// 			edge='end'
                           // 		// 			onClick={() => {
                           // 		// 				setFieldValue('name', '');
                           // 		// 			}}>
                           // 		// 			<HighlightOffIcon />
                           // 		// 		</IconButton>
                           // 		// 	</InputAdornment>
                           // 		// ),
                           // 	}
                           // }
                        />
                        <span
                           style={{
                              color: 'red',
                              fontFamily: "'Raleway', 'Montserrat', sans-serif",
                           }}
                        >
                           {errors.email && touched.email && errors.email}
                        </span>
                        <Typography
                           sx={{
                              lineHeight: 1,
                              fontWeight: 600,
                              fontFamily: "'Raleway', 'Montserrat', sans-serif",
                              fontSize: '0.9rem',
                              mt: 2,
                              mb: '5px',
                           }}
                        >
                           Subject
                        </Typography>
                        <TextInput
                           fullWidth
                           margin="normal"
                           name="feature"
                           placeholder="Enter Subject Title"
                           error={Boolean(touched.feature && errors.feature)}
                           onBlur={handleBlur}
                           onChange={handleChange}
                           type={'text'}
                           value={values.feature}
                           variant="outlined"
                        />
                        <span
                           style={{
                              color: 'red',
                              fontFamily: "'Raleway', 'Montserrat', sans-serif",
                           }}
                        >
                           {errors.feature && touched.feature && errors.feature}
                        </span>
                        <Typography
                           sx={{
                              lineHeight: 1,
                              fontWeight: 600,
                              fontFamily: "'Raleway', 'Montserrat', sans-serif",
                              fontSize: '0.9rem',
                              mt: 2,
                           }}
                        >
                           Description
                        </Typography>
                        <DescBox
                           name="desc"
                           placeholder="Enter description"
                           // error={Boolean(touched.desc && errors.desc)}
                           onBlur={handleBlur}
                           onChange={handleChange}
                           value={values.desc}
                           variant="outlined"
                        />
                        <span
                           style={{
                              color: 'red',
                              fontFamily: "'Raleway', 'Montserrat', sans-serif",
                           }}
                        >
                           {errors.desc && touched.desc}
                        </span>
                        <Box display={'flex'} sx={{ marginTop: '5rem' }}>
                           <FlatButton
                              disabled={
                                 !values.email ||
                                 !values.feature ||
                                 !values.desc
                              }
                              type="submit"
                           >
                              Send
                           </FlatButton>
                        </Box>
                     </form>
                  )}
               </Formik>
            </Box>
         </Card>
      </Container>
   );
};

export default Contact;
