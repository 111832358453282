import React from 'react';
import {
   Box,
   Typography,
   Card,
   Button,
   Checkbox,
   FormControlLabel,
} from '@mui/material';
import * as Yup from 'yup';
import backImg from '../../../assets/back.svg';
import styled from '@emotion/styled';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { contactSelector } from '../../../components/utils/selectors/selectors';
import { addSelectedNumbers } from '../../app/features/message/messageSlice';

const Container = styled(Box)(
   ({ theme }) => `
 padding:  ${theme.header.height} 0 2rem 0; 
 min-height: 100vh; 
 background: #EFEEF4;
 `
);

export const FlatButton = styled(Button)(
   ({ theme }) => `
    background: #374DA0;
    color: #fff;
    font-weight: 600;
    width: 50%;
    text-align: center;
    padding:10px 0;
    font-size: 1rem;
    border-radius:5px;
    margin: 1rem auto;

    &:hover {background: #374DA0;}
    @media(max-width:615px) {
    width:80%;
    }
    `
);

const TextInput = styled.textarea(
   ({ theme }) => `
       background: white;
       color: #49454F;
       width:100%;
       height:20vh;
       padding:5px
     `
);

const PasteSelect = () => {
   const [isOpen, setIsOpen] = React.useState(false);
   const [contacts, setContacts] = React.useState([]);
   const [checkedList, setCheckedList] = React.useState([]);
   const [selectAll, setSelectAll] = React.useState(true);
   const dispatch = useDispatch();
   const selector = useSelector(contactSelector);
   React.useEffect(() => {
      if (selector.created === true) {
         setIsOpen(true);
      }
   }, [selector.created]);
   React.useEffect(() => {
      if (selectAll) {
         setCheckedList([...contacts.map((x) => x.id)]);
      } else {
         setCheckedList([]);
      }
   }, [selectAll]);
   React.useEffect(() => {
      setCheckedList([...contacts.map((x) => x.id)]);
   }, [contacts]);

   const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
   return (
      <Container>
         <Card
            sx={{
               width: '90%',
               maxWidth: '615px',
               margin: '0 auto',
               marginTop: '2rem',
               position: 'relative',
               boxShadow:
                  '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
               padding: '20px ',
               background: '#EFEEF4',
            }}
         >
            <Box
               sx={{
                  margin: '0 auto',
                  marginTop: '1rem',
                  border: '1px solid transparent',
                  position: 'relative',
               }}
            >
               <img
                  src={backImg}
                  alt="back"
                  style={{
                     position: 'absolute',
                     width: '40px',
                     cursor: 'pointer',
                  }}
                  onClick={() => window.history.back()}
               />

               <Typography
                  sx={{
                     width: 'max-content',
                     fontWeight: 600,
                     fontSize: 'clamp(1.1rem, 2vw, 1.25rem)',
                     color: '#0F0E0E',
                     margin: '2rem auto',
                     marginBottom: '3rem',
                  }}
               >
                  Paste Contacts
               </Typography>

               <Formik
                  initialValues={{
                     numbers: '',
                     userId: selector?.admin?.id,
                  }}
                  validationSchema={Yup.object().shape({
                     numbers: Yup.string().required(
                        'At least one Phone number is required'
                     ),
                  })}
               >
                  {({
                     errors,
                     handleBlur,
                     handleChange,
                     handleSubmit,
                     isSubmitting,
                     touched,
                     values,
                     setFieldValue,
                  }) => (
                     <form noValidate onSubmit={handleSubmit}>
                        <label
                           htmlFor="numbers"
                           style={{ marginBottom: '5px', display: 'block' }}
                        >
                           Contacts
                        </label>
                        <TextInput
                           name="numbers"
                           id="numbers"
                           placeholder="Paste numbers here"
                           onBlur={handleBlur}
                           onChange={(e) => {
                              const numbers = e.target.value;
                              setFieldValue('numbers', numbers);

                              if (numbers) {
                                 const re = /(?:[-+() ]*\d){10,13}/gm;
                                 const match = numbers.match(re);
                                 if (match) {
                                    const res = match.map((s, i) => {
                                       return {
                                          id: i,
                                          fullName: '',
                                          number: s.trim(),
                                       };
                                    });
                                    setContacts(res);
                                 } else {
                                    setContacts([]);
                                 }
                              } else {
                                 setContacts([]);
                              }
                           }}
                           value={values.numbers}
                        />
                        <Box
                           sx={{
                              marginTop: '2rem',
                              height: '35vh',
                              overflowY: 'auto',
                           }}
                        >
                           {contacts != null && contacts.length > 0 && (
                              <FormControlLabel
                                 control={
                                    <Checkbox
                                       checked={
                                          checkedList.length == contacts.length
                                             ? true
                                             : selectAll
                                       }
                                       {...label}
                                       onChange={(e) => {
                                          const { checked } = e.target;
                                          setSelectAll(checked);
                                       }}
                                    />
                                 }
                                 label="Select all"
                              />
                           )}
                           {contacts != null &&
                              contacts?.map((item) => (
                                 <Card
                                    sx={{
                                       display: 'flex',
                                       paddingY: '10px',
                                       paddingLeft: '15px',
                                       marginBottom: '10px',
                                       cursor: 'pointer',
                                       '&:hover': {
                                          boxShadow:
                                             '0px 0px 1px 0px rgba(0,0,0,0.3)',
                                       },
                                    }}
                                    key={item?.id}
                                 >
                                    <Checkbox
                                       value={item?.id}
                                       checked={checkedList.includes(item?.id)}
                                       {...label}
                                       onClick={(e) => {
                                          const { value, checked } = e.target;

                                          if (checked) {
                                             setCheckedList([
                                                ...checkedList,
                                                value * 1,
                                             ]);
                                          } else {
                                             setCheckedList(
                                                checkedList.filter(
                                                   (item) => item != value
                                                )
                                             );
                                          }
                                       }}
                                    />
                                    <Box
                                       sx={{ paddingLeft: '15px' }}
                                       key={item?.id}
                                    >
                                       <Typography
                                          sx={{
                                             fontSize:
                                                'clamp(0.9rem, 1.5vw, 1rem)',
                                             fontWeight: 600,
                                          }}
                                       >
                                          {item.fullName}
                                       </Typography>
                                       <Typography
                                          sx={{
                                             fontSize:
                                                'clamp(0.9rem, 1.5vw, 1rem)',
                                             fontWeight: 600,
                                          }}
                                       >
                                          {item.number}
                                       </Typography>
                                    </Box>
                                 </Card>
                              ))}
                        </Box>

                        <Box display={'flex'} sx={{ marginTop: '1rem' }}>
                           <FlatButton
                              disabled={
                                 !values.numbers ||
                                 Boolean(
                                    errors.numbers &&
                                       touched.numbers &&
                                       errors.numbers
                                 )
                              }
                              onClick={() => {
                                 dispatch(
                                    addSelectedNumbers([
                                       ...contacts
                                          .filter((x) =>
                                             checkedList.includes(x.id)
                                          )
                                          .map((x) => x.number),
                                    ])
                                 );
                                 window.history.back();
                              }}
                           >
                              Add contacts
                           </FlatButton>
                        </Box>
                     </form>
                  )}
               </Formik>
            </Box>
         </Card>
      </Container>
   );
};

export default PasteSelect;
