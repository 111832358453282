import React from 'react';
import {
   Box,
   Typography,
   Card,
   Button,
   TextField,
   InputAdornment,
   IconButton,
   Dialog,
} from '@mui/material';
import * as Yup from 'yup';
import backImg from '../../../assets/back.svg';
import contactImg from '../../../assets/contactImg.svg';
import phoneImg from '../../../assets/phoneImg.svg';
// import verticalMenu from '../../../assets/verticalMenu.svg';
import styled from '@emotion/styled';
import { Formik } from 'formik';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Success from './Success';
import { useDispatch, useSelector } from 'react-redux';
import { contactSelector } from '../../../components/utils/selectors/selectors';
import { createContact } from '../../../components/app/features/contacts/contactSlice';

const Container = styled(Box)(
   ({ theme }) => `
 padding:  ${theme.header.height} 0 2rem 0; 
 min-height: 100vh; 
 background: #EFEEF4;
 `
);

export const FlatButton = styled(Button)(
   ({ theme }) => `
background: #374DA0;
color: #fff;
font-weight: 600;
width: 50%;
text-align: center;
padding:10px 0;
font-size: 1rem;
border-radius:5px;
margin: 1rem auto;

&:hover {background: #374DA0;}
@media(max-width:615px) {
   width:80%;
}
`
);

const TextInput = styled(TextField)(
   ({ theme }) => `
       background: white;
       color: #49454F;
     
     `
);

const Direct = () => {
   const [isOpen, setIsOpen] = React.useState(false);

   const phoneRegExp = /^(?:(?:\+234)|0)[1-9](?:(?:\d{8})|(?:\d{9}))$/;
   const dispatch = useDispatch();
   const selector = useSelector(contactSelector);
   React.useEffect(() => {
      if (selector.created === true) {
         setIsOpen(true);
      }
   }, [selector.created]);

   return (
      <Container>
         <Card
            sx={{
               width: '90%',
               maxWidth: '615px',
               margin: '0 auto',
               marginTop: '2rem',
               position: 'relative',
               boxShadow:
                  '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
               padding: '20px ',
               background: '#EFEEF4',
            }}
         >
            <Box
               sx={{
                  margin: '0 auto',
                  marginTop: '1rem',
                  border: '1px solid transparent',
                  position: 'relative',
               }}
            >
               <img
                  src={backImg}
                  alt="back"
                  style={{
                     position: 'absolute',
                     width: '40px',
                     cursor: 'pointer',
                  }}
                  onClick={() => window.history.back()}
               />

               <Typography
                  sx={{
                     width: 'max-content',
                     fontWeight: 600,
                     fontSize: 'clamp(1.1rem, 2vw, 1.25rem)',
                     color: '#0F0E0E',
                     margin: '2rem auto',
                     marginBottom: '3rem',
                  }}
               >
                  Create Contacts
               </Typography>

               <Formik
                  initialValues={{
                     name: '',
                     phone: '',
                     userId: selector?.admin?.id,
                  }}
                  validationSchema={Yup.object().shape({
                     name: Yup.string(),
                     phone: Yup.string()
                        .matches(phoneRegExp, 'Invalid phone number')
                        .required('Phone number is required'),
                  })}
                  onSubmit={async (values, { setSubmitting }) => {
                     const contact = [
                        {
                           fullName:
                              values.name !== '' ? values.name : 'unknown',
                           number: values.phone,
                        },
                     ];
                     dispatch(
                        createContact({
                           userId: selector?.admin?.id,
                           contact: contact,
                        })
                     );

                     setTimeout(() => {
                        setSubmitting(false);
                     }, 400);
                  }}
               >
                  {({
                     errors,
                     handleBlur,
                     handleChange,
                     handleSubmit,
                     isSubmitting,
                     touched,
                     values,
                     setFieldValue,
                  }) => (
                     <form noValidate onSubmit={handleSubmit}>
                        <label
                           htmlFor="name"
                           style={{ marginBottom: '5px', display: 'block' }}
                        >
                           Name
                        </label>
                        <TextInput
                           // error={Boolean(touched.name && errors.name)}
                           fullWidth
                           margin="normal"
                           name="name"
                           id="name"
                           placeholder="Enter your name"
                           onBlur={handleBlur}
                           onChange={handleChange}
                           type="text"
                           value={values.name}
                           variant="outlined"
                           InputProps={{
                              startAdornment: (
                                 <InputAdornment position="start">
                                    <img src={contactImg} alt="contact" />
                                 </InputAdornment>
                              ),
                              endAdornment: (
                                 <InputAdornment position="end">
                                    <IconButton
                                       edge="end"
                                       onClick={() => {
                                          setFieldValue('name', '');
                                       }}
                                    >
                                       <HighlightOffIcon />
                                    </IconButton>
                                 </InputAdornment>
                              ),
                           }}
                        />
                        {/* <span style={{ color: 'red' }}>
									{errors.name && touched.name && errors.name}
								</span> */}

                        <label
                           htmlFor="phone"
                           style={{
                              marginBottom: '5px',
                              marginTop: '2rem',
                              display: 'block',
                           }}
                        >
                           Phone number
                        </label>
                        <TextInput
                           fullWidth
                           margin="normal"
                           name="phone"
                           id="phone"
                           placeholder="Enter your phone number"
                           error={Boolean(touched.phone && errors.phone)}
                           onBlur={handleBlur}
                           onChange={handleChange}
                           type="text"
                           value={values.phone}
                           variant="outlined"
                           InputProps={{
                              startAdornment: (
                                 <InputAdornment position="start">
                                    <img src={phoneImg} alt="phone" />
                                 </InputAdornment>
                              ),
                              endAdornment: (
                                 <InputAdornment position="end">
                                    <IconButton
                                       edge="end"
                                       onClick={() => {
                                          setFieldValue('phone', '');
                                       }}
                                    >
                                       <HighlightOffIcon />
                                    </IconButton>
                                 </InputAdornment>
                              ),
                           }}
                        />
                        <span style={{ color: 'red' }}>
                           {errors.phone && touched.phone && errors.phone}
                        </span>
                        <Box display={'flex'} sx={{ marginTop: '9rem' }}>
                           <FlatButton
                              disabled={
                                 // !values.name ||
                                 !values.phone ||
                                 Boolean(
                                    errors.phone &&
                                       touched.phone &&
                                       errors.phone
                                 )
                                 // Boolean(errors.name && touched.name && errors.name)
                                 // ||	selector.loading === 'pending'
                              }
                              type="submit"
                           >
                              Add contact
                           </FlatButton>
                        </Box>
                     </form>
                  )}
               </Formik>
               <Dialog
                  onClose={() => {
                     setIsOpen(false);
                  }}
                  open={isOpen}
               >
                  <Success count={1} />
               </Dialog>
            </Box>
         </Card>
      </Container>
   );
};

export default Direct;
