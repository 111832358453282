import React from 'react';
import { Box, styled, Typography, Dialog, Card } from '@mui/material';
import DashBoardLink from '../../dashbaordLink/DashboardLink';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import SettingsIcon from '../../../assets/Settings.svg';
import LogoutIcon from '../../../assets/LogoutIcon.svg';

const SidebarWrapper = styled(Box)(
   ({ theme }) => `
          width: ${theme.sidebar.width};
          margin-top:  ${theme.header.height};
          min-width: ${theme.sidebar.width};
          background: ${theme.sidebar.background};
          position: fixed;
          z-index: 7;
         bottom:0;
			top:0;
			left:0;									
			transition: transform 0.2s ease-in-out;
         display:flex;
         flex-direction: column;
         justify-content: space-between;
			@media (min-width: ${theme.breakpoints.values.myCustomQueryI}px) {
            transform:translateX(-100%) ;
        }
  `
);

const boxSX = {
   py: '8px',
   pl: '22px',
   mt: '17px',
   color: '#ffffff',
   cursor: 'pointer',
   borderLeft: '4px solid #7A88C0',
   '&:hover': {
      color: '#ffffff',
      backgroundColor: '#2E4085',
      borderLeft: '5px solid white',
   },
};
const activeLink = {
   py: '12px',
   pl: '22px',
   mt: '17px',
   cursor: 'pointer',
   color: '#ffffff',
   backgroundColor: '#2E4085',
   borderLeft: '4px solid white',
};

const HamSidebar = ({ openMenu, getHamState, getLogOutState }) => {
   const [state, setState] = React.useState(false);
   const location = useLocation();

   React.useEffect(() => {
      if (openMenu && window.innerWidth <= 1120) {
         setState(true);
      } else {
         setState(false);
      }
   }, [openMenu]);

   const handleClose = () => {
      setState(false);
      getHamState(false);
   };

   return (
      <Dialog
         onClose={() => handleClose()}
         open={state === false ? false : true}
         sx={{ zIndex: 99 }}
      >
         <SidebarWrapper
            style={{
               transform: state ? 'translateX(0%)' : 'translateX(-100%)',
            }}
         >
            <DashBoardLink />

            <Box>
               <Link
                  to="/settings"
                  style={{
                     textDecoration: 'none',
                  }}
               >
                  <Box mt={20}>
                     <Box
                        display="flex"
                        textAlign="center"
                        alignItems="center"
                        gap={1.3}
                        sx={
                           location.pathname == '/settings' ? activeLink : boxSX
                        }
                     >
                        <img
                           src={SettingsIcon}
                           alt="message"
                           style={{
                              marginLeft: '-5px',
                           }}
                        />
                        <Typography
                           variant="h5"
                           fontSize="16px"
                           fontWeight={500}
                        >
                           Settings
                        </Typography>
                     </Box>
                  </Box>
               </Link>
               <Box
                  display="flex"
                  textAlign="center"
                  alignItems="center"
                  gap={1.3}
                  sx={boxSX}
                  onClick={() => {
                     getLogOutState(true);
                  }}
               >
                  <img
                     src={LogoutIcon}
                     alt="message"
                     style={{
                        marginLeft: '-5px',
                     }}
                  />
                  <Typography variant="h5" fontSize="16px" fontWeight={500}>
                     Log out
                  </Typography>
               </Box>
            </Box>
         </SidebarWrapper>
      </Dialog>
   );
};

export default HamSidebar;
