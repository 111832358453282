import React from 'react';
import Logo from '../../../assets/ParroticaLogo.svg';
import propTypes from 'prop-types';
import './AuthLayout.css';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

const AuthLayout = ({ layoutImage }) => {
   return (
      <Box height="100vh" display="flex" className="authLayout">
         <Box height="100%" className="pageWrapper">
            <img src={layoutImage} alt="signup" className="signupCover" />
         </Box>
         <Box
            display="flex"
            flexDirection="column"
            pt={1.5}
            textAlign="center"
            className="pageBox"
         >
            <Box
               className="boxWrapper"
               sx={{
                  backgroundColor: '#374DA0',
                  height: '70px',
                  width: '90%',
                  marginX: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
               }}
            >
               <Box
                  sx={{
                     width: '100px',
                     marginTop: '1%',
                  }}
               >
                  <img
                     src={Logo}
                     alt="logo"
                     style={{
                        width: '100%',
                        height: '100%',
                     }}
                  />
               </Box>
            </Box>
            <Box
               className="formWrapper"
               sx={{
                  width: '89.7%',
                  marginX: 'auto',
                  paddingX: '25px',
                  height: '86vh',
               }}
            >
               <Outlet />
            </Box>
         </Box>
      </Box>
   );
};

AuthLayout.propTypes = {
   layoutImage: propTypes.any,
};

export default AuthLayout;
