import httpclient, { initialiseRefreshToken } from '../httpClient.js';
import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import setAuthToken from '../../../utils/setAuthToken.js';
import { getBaseUrls, userLSAuth } from '../env.js';

const initialState = {
   loading: 'idle',
   admin: userLSAuth(),
   error: null,
   avatar: [],
   displayPicture: '',
   verifyPhoneLoading: 'idle',
   verifyEmailLoading: 'idle',
   avatarUploadeded: 'idle',
   created: false,
   refresh: false,
   updatedProfile: null,
   updated: false,
   logOut: false,
   isLoggedIn: false,
};
// User create account
export const createAccount = createAsyncThunk(
   'auth/signup',
   async (payload, { rejectWithValue }) => {
      try {
         const config = {
            headers: {
               'Content-Type': 'application/json',
            },
         };
         const response = await httpclient.post(
            `${getBaseUrls().auth}/api/v1/auth/signup`,
            {
               fullName: payload.firstName + ' ' + payload.lastName,
               email: payload.email,
               // phone: payload.phoneNumber,
               password: payload.password,
               confirmPassword: payload.confirmPassword,
            },
            config
         );

         if (
            response.status === 200 ||
            response.status === 201 ||
            response.status === 'success'
         ) {
            // store the userId
            localStorage.setItem(
               'userAuth',
               JSON.stringify(response.data.data)
            );
            return response.data.data;
         }

         return rejectWithValue(response);
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

// Verify Email Address
export const verifyEmail = createAsyncThunk(
   'verify/email',
   async (payload, { rejectWithValue }) => {
      try {
         const config = {
            'Content-Type': 'application/json',
         };
         const response = await httpclient.post(
            `${getBaseUrls().auth}/api/v1/auth/verify-email`,
            {
               verificationCode: Number(payload.email),
               userId: Number(
                  payload.userId ? payload.userId : userLSAuth().id
               ),
            },
            config
         );

         if (
            response.status === 200 ||
            response.status === 201 ||
            response.status === 'success'
         ) {
            // store the userId
            // toast.success(response.data.message);

            localStorage.setItem(
               'userAuth',
               JSON.stringify(response.data.data)
            );
            localStorage.setItem('accessToken', response.data.meta.accessToken);

            return response.data.data;
         } else if (response.status === 400) {
            toast.error(response.data.message);
         }

         return rejectWithValue(response);
      } catch (error) {
         toast.error(error.response.data.message);
         if (error.response.data.meta) {
            localStorage.setItem(
               'userAuth',
               JSON.stringify(error.response.data.meta)
            );
         }
         return rejectWithValue(error);
      }
   }
);

// Reset Password /api/v1/auth/reset-password
export const resetPassword = createAsyncThunk(
   'user/reset',
   async (payload, { rejectWithValue }) => {
      try {
         const config = {
            'Content-Type': 'application/json',
         };
         const response = await httpclient.post(
            `${getBaseUrls().auth}/api/v1/auth/reset-password`,
            {
               email: payload.email,
            },
            config
         );

         if (response.status === 200) {
            toast.success(response.data.message);
            return response.data.data;
         }

         if (response.status === 204) {
            toast.success(
               'A link to reset your password has been sent to your email'
            );
         }
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

// verify Phone
export const verifyPhone = createAsyncThunk(
   'verify/phone',
   async (payload, { rejectWithValue }) => {
      try {
         const config = {
            'Content-Type': 'application/json',
         };
         const response = await httpclient.post(
            `${getBaseUrls().auth}/api/v1/auth/verify-phone`,
            {
               verificationCode: Number(payload.phoneNumber),
               userId: Number(
                  payload.userId ? payload.userId : userLSAuth().id
               ),
            },
            config
         );

         if (
            response.status === 200 ||
            response.status === 201 ||
            response.status === 'success'
         ) {
            localStorage.setItem(
               'userAuth',
               JSON.stringify(response.data.data)
            );
            localStorage.setItem('accessToken', response.data.meta.accessToken);

            // store the userId
            return response.data.data;
         }

         return rejectWithValue(response);
      } catch (error) {
         localStorage.setItem(
            'userAuth',
            JSON.stringify(error.response.data.meta)
         );
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);
// /api/v1/auth/change-password
// Generate OTP
export const generateOTP = createAsyncThunk(
   'auth/otp',
   async (payload, { rejectWithValue }) => {
      try {
         const userId = payload.userId ? payload.userId : userLSAuth().id;
         const config = {
            headers: {
               'Content-Type': 'application/json',
            },
         };
         const response = await httpclient.get(
            `${getBaseUrls().auth}/api/v1/auth/otp/${userId}?type=${
               payload.type
            }`,
            config
         );
         if (response.status && response.status === 200) {
            toast.success(response.data.message);

            return response.data.data;
         }

         return rejectWithValue(response.data.data);
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

export const createPassword = createAsyncThunk(
   'auth/newPassword',
   async (payload, { rejectWithValue }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token ?? payload.accessToken);
      try {
         const config = {
            headers: {
               'Content-Type': 'application/json',
            },
         };
         const response = await httpclient.post(
            `${getBaseUrls().auth}/api/v1/auth/change-password`,
            {
               password: payload.password,
               confirmPassword: payload.confirmPassword,
               userId: Number(
                  payload.userId ? payload.userId : userLSAuth().id
               ),
            },
            config
         );

         if (
            response.status === 204 ||
            response.status == 200 ||
            response.status === 'success'
         ) {
            toast.success(response.data.message);
            return response.data.data;
         }

         return rejectWithValue(response.data);
      } catch (error) {
         toast.error(error.response.data.message);

         return rejectWithValue(error);
      }
   }
);

// user login email
export const userLogin = createAsyncThunk(
   'login/fetchByIdStatus',
   async (payload, { rejectWithValue }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);
      localStorage.setItem('stayLoggedIn', payload.stayLoggedIn);
      try {
         const config = {
            withCredentials: true,
            headers: {
               'Content-Type': 'application/json',
            },
         };
         const response = await httpclient.post(
            `${getBaseUrls().auth}/api/v1/auth/login`,
            {
               email: payload.email,
               password: payload.password,
               phone: null,
            },
            config
         );

         if (response.status === 200 || response.status === 201) {
            toast.success(response.data.message);
            localStorage.setItem(
               'userAuth',
               JSON.stringify(response.data.data)
            );
            initialiseRefreshToken(response.data.meta.expiresIn);
            localStorage.setItem('accessToken', response.data.meta.accessToken);

            return response.data.data;
         }

         return rejectWithValue(response);
      } catch (error) {
         toast.error(error.response.data.message);
         localStorage.setItem(
            'userAuth',
            JSON.stringify(error.response?.data?.meta)
         );
         return rejectWithValue(error);
      }
   }
);

// user login phone
export const userloginPhone = createAsyncThunk(
   'user/loginByPhone',
   async (payload, { rejectWithValue }) => {
      try {
         localStorage.setItem('stayLoggedIn', payload.stayLoggedIn);
         const config = {
            headers: {
               'Content-Type': 'application/json',
            },
         };
         const response = await httpclient.post(
            `${getBaseUrls().auth}/api/v1/auth/login`,
            {
               Phone: payload.phoneNumber,
               password: payload.password,
               email: null,
            },
            config
         );

         if (response.status === 200 || response.status === 201) {
            toast.success(response.data.message);
            localStorage.setItem(
               'userAuth',
               JSON.stringify(response.data.data)
            );
            localStorage.setItem('accessToken', response.data.meta.accessToken);
            initialiseRefreshToken(response.data.meta.expiresIn);
            return response.data.data;
         }

         return rejectWithValue(response);
      } catch (error) {
         toast.error(error.response.data.message[0]);
         localStorage.setItem(
            'userAuth',
            JSON.stringify(error.response.data.meta)
         );
         return rejectWithValue(error);
      }
   }
);

// Select Avatar
export const selectAvatar = createAsyncThunk(
   'get/avatar',
   async (_, { rejectWithValue }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);

      try {
         const response = await httpclient.get(
            `${getBaseUrls().auth}/api/v1/auth/avatar?ext=svg`
         );
         if (response.status && response.status === 200) {
            // toast.success(response.data.message);
            return response.data.data;
         }

         return rejectWithValue(response.data.data);
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

// Upload Avatar
export const UploadAvatar = createAsyncThunk(
   'upload/avatar',
   async (payload, { rejectWithValue }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);

      try {
         const response = await httpclient.post(
            `${getBaseUrls().auth}/api/v1/auth/avatar/${userLSAuth().id}`,
            payload.formData,
            {
               headers: {
                  'Content-Type': 'multipart/form-data',
               },
            }
         );

         if (response.status && response.status === 200) {
            localStorage.setItem(
               'userAuth',
               JSON.stringify(response.data.data)
            );
            // localStorage.setItem('accessToken', response.data.meta.accessToken);
            return response.data.data;
         }
         return rejectWithValue(response.data.data);
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

// Upload logout
export const logOut = createAsyncThunk(
   'user/logOut',
   async (payload, { rejectWithValue }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);

      try {
         const response = await httpclient.post(
            `${getBaseUrls().auth}/api/v1/auth/logout`,
            { userId: userLSAuth().id }
         );

         if (response.status && response.status === 200) {
            return response.data.data;
         }

         return rejectWithValue(response.data.data);
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

// edit profile
export const getUserProfile = createAsyncThunk(
   'get/profile',
   async (payload, { rejectWithValue }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);

      try {
         const response = await httpclient.get(
            `${getBaseUrls().auth}/api/v1/auth/profile/${userLSAuth().id}`
         );

         if (response.status === 200) {
            localStorage.setItem(
               'userAuth',
               JSON.stringify(response.data.data)
            );

            return response.data;
         }

         return rejectWithValue(response.data.data);
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

// edit profile
export const editUserProfile = createAsyncThunk(
   'edit/profile',
   async (payload, { rejectWithValue }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);

      try {
         const config = {
            headers: {
               'Content-Type': 'application/json',
            },
         };
         const response = await httpclient.post(
            `${getBaseUrls().auth}/api/v1/auth/profile/${userLSAuth().id}`,
            payload,
            config
         );

         if (response.status === 200) {
            toast.success(response.data.message);
            localStorage.setItem(
               'userAuth',
               JSON.stringify(response.data.data)
            );
            return response.data;
         }

         return rejectWithValue(response.data.data);
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

const authSlice = createSlice({
   name: 'auth',
   initialState,
   reducers: {
      resetLoading: (state) => {
         state.loading = 'idle';
      },
      setLoading: (state) => {
         state.loading = 'pending';
      },
      resetCreated: (state, action) => {
         state.created = false;
         state.refresh = true;
      },
      resetLogout: (state, action) => {
         state.logOut = false;
         state.isLoggedIn = false;
      },
      resetAvatarSuccess: (state, action) => {
         state.avatarUploadeded = 'false';
      },
      resetErrorOnLogin: (state, action) => {
         state.error = null;
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(createAccount.pending, (state) => {
            state.loading = 'pending';
         })
         .addCase(createAccount.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload.response.status;
            state.action = action.payload.response.data.meta.action;
         })
         .addCase(createAccount.fulfilled, (state, action) => {
            state.loading = 'success';
            state.admin = action.payload;
         })
         .addCase(userLogin.pending, (state) => {
            state.loading = 'pending';
         })
         .addCase(userLogin.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload.response.data.message;
         })
         .addCase(userLogin.fulfilled, (state, action) => {
            state.loading = 'success';
            state.isLoggedIn = true;
            state.admin = action.payload;
         })
         .addCase(userloginPhone.pending, (state) => {
            state.loading = 'pending';
         })
         .addCase(userloginPhone.rejected, (state, action) => {
            state.loading = 'failed';

            state.error = action.payload.response.data.message;
         })
         .addCase(userloginPhone.fulfilled, (state, action) => {
            state.loading = 'success';
            state.isLoggedIn = true;
            state.admin = action.payload;
         })
         .addCase(generateOTP.pending, (state) => {
            state.loading = 'pending';
         })
         .addCase(generateOTP.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(generateOTP.fulfilled, (state, action) => {
            state.loading = 'success';
         })
         .addCase(verifyEmail.pending, (state) => {
            state.verifyEmailLoading = 'pending';
         })
         .addCase(verifyEmail.rejected, (state, action) => {
            state.verifyEmailLoading = 'failed';
            state.error = action.payload;
         })
         .addCase(verifyEmail.fulfilled, (state, action) => {
            state.verifyEmailLoading = 'success';
            state.admin = action.payload;
         })
         .addCase(verifyPhone.pending, (state) => {
            state.verifyPhoneLoading = 'pending';
         })
         .addCase(verifyPhone.rejected, (state, action) => {
            state.verifyPhoneLoading = 'failed';
            state.error = action.payload;
         })
         .addCase(verifyPhone.fulfilled, (state, action) => {
            state.verifyPhoneLoading = 'success';
            state.admin = action.payload;
         })
         .addCase(selectAvatar.pending, (state) => {
            state.loading = 'pending';
         })
         .addCase(selectAvatar.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(selectAvatar.fulfilled, (state, action) => {
            state.loading = 'success';
            state.avatar = action.payload;
         })
         .addCase(UploadAvatar.pending, (state) => {
            state.avatarUploadeded = 'pending';
         })
         .addCase(UploadAvatar.rejected, (state, action) => {
            state.avatarUploadeded = 'failed';
            state.error = action.payload;
         })
         .addCase(UploadAvatar.fulfilled, (state, action) => {
            state.avatarUploadeded = 'success';
            // state.displayPicture = action.payload;
         })
         .addCase(createPassword.pending, (state) => {
            state.loading = 'pending';
         })
         .addCase(createPassword.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(createPassword.fulfilled, (state, action) => {
            state.loading = 'success';
            state.avatar = action.payload;
         })
         .addCase(logOut.pending, (state) => {
            state.loading = 'pending';
            state.logOut = true;
         })
         .addCase(logOut.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(logOut.fulfilled, (state, action) => {
            state.loading = 'success';
         })
         .addCase(editUserProfile.pending, (state) => {
            state.loading = 'pending';
         })
         .addCase(editUserProfile.fulfilled, (state, action) => {
            state.loading = 'success';
            state.refresh = true;
            state.created = true;
         })
         .addCase(editUserProfile.rejected, (state, action) => {
            state.loading = 'failed';

            state.error = action.payload;
         })
         .addCase(getUserProfile.pending, (state) => {
            state.loading = 'pending';
         })
         .addCase(getUserProfile.fulfilled, (state, action) => {
            state.loading = 'success';
            state.updatedProfile = action.payload.data;
            state.updated = true;
         })
         .addCase(getUserProfile.rejected, (state, action) => {
            state.loading = 'failed';

            state.error = action.payload;
         });
   },
});

export default authSlice.reducer;
export const {
   resetLoading,
   resetCreated,
   resetLogout,
   resetAvatarSuccess,
   resetErrorOnLogin,
   setLoading,
} = authSlice.actions;
