import React from 'react';
import { Box, Typography, Button, Card } from '@mui/material';
import styled from '@emotion/styled';
import { Container } from './style';
import { useNavigate } from 'react-router-dom';
import buttonIcon from '../../../assets/buttonIcon.png';
import sendMessage from '../../../assets/sendMessage.png';
import messageHistoryIcon from '../../../assets/messageHistory.svg';
import messageRulesIcon from '../../../assets/messageRules.svg';
import draftsIcon from '../../../assets/drafts.svg';
import scheduledMessage from '../../../assets/scheduledMessage.svg';

const FlatButton = styled(Button)(
   ({ theme }) => `
background: #D7DBEC;
color: #374DA0;
font-weight: 600;
width: 47%;
text-align: center;
position: relative;
padding:'10px 0';
@media(max-width:550px) {
	width: 100%;
}
`
);

const MessageHome = () => {
   const navigate = useNavigate();
   return (
      <Container>
         <Card
            sx={{
               width: '90%',
               maxWidth: '615px',
               margin: '0 auto',
               position: 'relative',
               boxShadow:
                  '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
               padding: '20px ',
               background: '#EFEEF4',
            }}
         >
            <Box
               sx={{
                  margin: '0 auto',
               }}
            >
               <Typography
                  sx={{
                     textAlign: 'center',
                     fontSize: 'clamp(1.1rem, 2vw, 1.25rem)',
                     fontWeight: '600',
                     marginTop: '2rem',
                  }}
               >
                  Messages
               </Typography>
               <Box
                  sx={{
                     display: 'flex',
                     justifyContent: 'space-between',
                     marginTop: '2rem',
                     '@media(max-width: 550px)': {
                        flexDirection: 'column',
                        alignItems: 'center',
                        rowGap: '20px',
                     },
                  }}
               >
                  <FlatButton
                     sx={{
                        color: '#374DA0',
                     }}
                     onClick={() => navigate('/send-message/verify')}
                  >
                     <img
                        src={buttonIcon}
                        style={{
                           marginRight: '10px',
                           fontWeight: 700,
                           fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                        }}
                        alt="icon"
                     />
                     <Typography
                        sx={{
                           color: '#374DA0',
                           fontWeight: 700,
                           fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                        }}
                     >
                        Apply for Sender ID
                     </Typography>
                  </FlatButton>
                  <FlatButton
                     onClick={() => navigate('/send-message/message')}
                     sx={{
                        color: '#374DA0',
                     }}
                  >
                     <img
                        src={sendMessage}
                        style={{
                           marginRight: '10px',
                        }}
                        alt="icon"
                     />
                     <Typography
                        sx={{
                           color: '#374DA0',
                           fontWeight: 700,
                           fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                        }}
                     >
                        Send Message
                     </Typography>
                  </FlatButton>
               </Box>
               <Box
                  sx={{
                     margin: '2rem 0',
                     display: 'flex',
                     columnGap: '15px',
                     rowGap: '15px',
                     flexWrap: 'wrap',
                     '& > *:hover': {
                        transform: 'scale(1.02)',
                     },
                  }}
               >
                  <Box
                     sx={{
                        width: '48%',
                        height: '150px',
                        background: '#fff',
                        borderTopLeftRadius: '16px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        '@media (max-width: 460px)': {
                           width: '100%',
                           borderTopRightRadius: '16px',
                        },
                     }}
                     onClick={() => navigate('/send-message/history')}
                  >
                     <img
                        src={messageHistoryIcon}
                        style={{ width: '35px', marginBottom: '1rem' }}
                        alt="icon"
                     />
                     <Typography
                        sx={{
                           fontWeight: 600,
                        }}
                     >
                        Message History
                     </Typography>
                  </Box>
                  <Box
                     sx={{
                        width: '48%',
                        height: '150px',
                        background: '#fff',
                        borderTopRightRadius: '16px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        '@media (max-width: 460px)': {
                           width: '100%',
                           borderTopRightRadius: 0,
                        },
                     }}
                     onClick={() => navigate('/send-message/rules')}
                  >
                     <img
                        src={messageRulesIcon}
                        style={{ width: '35px', marginBottom: '1rem' }}
                        alt="icon"
                     />
                     <Typography
                        sx={{
                           fontWeight: 600,
                        }}
                     >
                        Message Guidelines
                     </Typography>
                  </Box>
                  <Box
                     sx={{
                        width: '48%',
                        height: '150px',
                        background: '#fff',
                        borderBottomLeftRadius: '16px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        '@media (max-width: 460px)': {
                           width: '100%',
                           borderBottomLeftRadius: 0,
                        },
                     }}
                     onClick={() => navigate('/send-message/schedule')}
                  >
                     <img
                        src={scheduledMessage}
                        style={{ width: '35px', marginBottom: '1rem' }}
                        alt="icon"
                     />
                     <Typography
                        sx={{
                           fontWeight: 600,
                        }}
                     >
                        Schedule Message
                     </Typography>
                  </Box>
                  <Box
                     sx={{
                        width: '48%',
                        height: '150px',
                        background: '#fff',
                        borderBottomRightRadius: '16px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        '@media (max-width: 460px)': {
                           width: '100%',
                           borderBottomLeftRadius: '16px',
                        },
                     }}
                     onClick={() => navigate('/send-message/drafts')}
                  >
                     <img
                        src={draftsIcon}
                        style={{ width: '35px', marginBottom: '1rem' }}
                        alt="icon"
                     />
                     <Typography
                        sx={{
                           fontWeight: 600,
                        }}
                     >
                        Draft
                     </Typography>
                  </Box>
               </Box>
            </Box>
         </Card>
      </Container>
   );
};

export default MessageHome;
