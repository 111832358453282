import React, { useEffect } from 'react';
import * as Yup from 'yup';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
// import GoogleIcon from '../../../../assets/GoogleIcons.svg';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import {
   Box,
   Button,
   TextField,
   Typography,
   CircularProgress,
   IconButton,
   FormControlLabel,
   Checkbox,
   InputAdornment,
   styled,
} from '@mui/material';
import { userLogin } from '../../../app/features/auth/AuthSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthSelector } from '../../../utils/selectors/selectors';
import { useNavigate } from 'react-router-dom';

const TextInput = styled(TextField)(
   ({ theme }) => `
       background: white;
       color: #49454F;
    
     `
);

const boxSX = {
   '&.Mui-checked': {
      color: '#374DA0',
   },
};

const EmailLogin = () => {
   const dispatch = useDispatch();
   const selector = useSelector(getAuthSelector);
   const navigate = useNavigate();

   const [showPassword, setShowPassword] = React.useState(false);
   const [stayLoggedIn, setStayLoggedIn] = React.useState(true);

   const handleClickShowPassword = () => setShowPassword((show) => !show);

   const handleMouseDownPassword = (event) => {
      event.preventDefault();
   };

   useEffect(() => {
      if (selector.isLoggedIn === true) {
         navigate('/dashboard');
      }
      // eslint-disable-next-line
   }, [selector.isLoggedIn]);

   useEffect(() => {
      if (
         selector.error === 'Please complete verification step' ||
         selector.error === 'Please email complete email verification step' ||
         selector.error === 'Please complete email verification step'
      ) {
         navigate('/account-verification');
      }
      // eslint-disable-next-line
   }, [selector.error]);

   return (
      <div>
         <Formik
            initialValues={{
               email: '',
               password: '',
               phone: null,
            }}
            validationSchema={Yup.object().shape({
               email: Yup.string()
                  .email('The email provided should be a valid email address')
                  .max(255)
                  .required('The email field is required'),
               password: Yup.string()
                  .max(255)
                  .required('Please enter your password'),
            })}
            onSubmit={async (
               values,
               { setErrors, setStatus, setSubmitting }
            ) => {
               dispatch(userLogin({ ...values, stayLoggedIn }));
            }}
         >
            {({
               errors,
               handleBlur,
               handleChange,
               handleSubmit,
               isSubmitting,
               touched,
               values,
               setFieldValue,
            }) => (
               <form noValidate onSubmit={handleSubmit}>
                  <Box
                     sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: 2,
                     }}
                  >
                     <TextInput
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        fullWidth
                        margin="normal"
                        name="email"
                        placeholder="Enter email address"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.email}
                        variant="outlined"
                        InputProps={{
                           // style: {
                           //    marginBottom: '1rem',
                           // },
                           startAdornment: (
                              <InputAdornment position="start">
                                 <EmailIcon />
                              </InputAdornment>
                           ),
                           endAdornment: (
                              <InputAdornment position="end">
                                 <IconButton
                                    edge="end"
                                    onClick={() => {
                                       setFieldValue('email', '');
                                    }}
                                 >
                                    <HighlightOffIcon />
                                 </IconButton>
                              </InputAdornment>
                           ),
                        }}
                     />
                     <TextInput
                        fullWidth
                        margin="normal"
                        name="password"
                        placeholder="Enter password"
                        helperText={touched.password && errors.password}
                        error={Boolean(touched.password && errors.password)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type={showPassword ? 'text' : 'password'}
                        value={values.password}
                        variant="outlined"
                        InputProps={{
                           startAdornment: (
                              <InputAdornment position="start">
                                 <LockIcon />
                              </InputAdornment>
                           ),
                           endAdornment: (
                              <InputAdornment position="end">
                                 <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                 >
                                    {showPassword ? (
                                       <VisibilityOff />
                                    ) : (
                                       <Visibility />
                                    )}
                                 </IconButton>
                              </InputAdornment>
                           ),
                        }}
                     />
                  </Box>
                  <Box
                     alignItems="center"
                     display="flex"
                     justifyContent="space-between"
                  >
                     <FormControlLabel
                        control={
                           <Checkbox
                              checked={stayLoggedIn}
                              sx={boxSX}
                              onChange={(e) => {
                                 setStayLoggedIn(e.target.checked);
                              }}
                           />
                        }
                        label={
                           <>
                              <Typography
                                 variant="body2"
                                 fontFamily="'Raleway', 'Montserrat', sans-serif"
                                 color="#0F0E0E"
                                 fontWeight={300}
                              >
                                 Keep me logged in
                              </Typography>
                           </>
                        }
                     />
                     <Link to="/forgotpassword" className="termsLink">
                        <b>Forgot Password?</b>
                     </Link>
                  </Box>
                  <Button
                     sx={{
                        borderRadius: '11px',
                        py: '7px',
                        fontSize: '15px',
                        width: '50%',
                        mx: 'auto',
                        mt: '20px',
                        '@media (max-width: 600px)': {
                           width: '80%',
                        },
                     }}
                     variant="contained"
                     size="small"
                     type="submit"
                     startIcon={
                        selector.loading === 'pending' ? (
                           <CircularProgress size="1rem" color="primary" />
                        ) : null
                     }
                     disabled={
                        !values.email ||
                        !values.password ||
                        selector.loading === 'pending'
                     }
                  >
                     Log in
                  </Button>
                  <br />
               </form>
            )}
         </Formik>
         <Typography mt={2}>
            Don't have an account?{' '}
            <Link to="/signup" className="termsLink">
               Sign up
            </Link>
         </Typography>
      </div>
   );
};

export default EmailLogin;
