// import * as Yup from 'yup';
import React, { useState } from 'react';
import {
   Dialog,
   styled,
   Typography,
   MenuItem,
   Select,
   FormControl,
   TextField,
   IconButton,
   InputAdornment,
   Button,
   Box,
   InputLabel,
   // Link,
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
// import ErrorSharpIcon from '@mui/icons-material/ErrorSharp';
import { useNavigate } from 'react-router-dom';
// import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { getTransactions } from '../../utils/selectors/selectors';
import {
   amountPayable,
   getAmountConv,
   getUnitsConv,
} from '../../app/features/transactions/TransactionSlice';
import { debounce } from 'lodash';
import { FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';

const DialogWrapper = styled(Dialog)(
   () => `
        .MuiDialog-paper {
          overflow: visible;
        }
     
  `
);

const TextInput = styled(TextField)(
   ({ theme }) => `
	margin-top:5px;
         background: white;
         color: #49454F;
           font-family: 'Raleway', 'Montserrat', sans-serif;
         &.placeholer{
          color: red;
         }
       `
);

const TopUpForm = ({ openForm, closeForm }) => {
   const [topUpMenu, setTopUpMenu] = useState('Amount');
   const [amount, setAmount] = useState('');
   const [units, setUnits] = useState('');
   const [enabled, setEnabled] = useState('');

   const selector = useSelector(getTransactions);
   const dispatch = useDispatch();

   const handleSelect = (event) => {
      setTopUpMenu(event.target.value);
   };

   const getUnits = debounce((value) => {
      dispatch(getAmountConv({ event: value }));
   }, 500);
   const getAmount = debounce((value) => {
      dispatch(getUnitsConv({ event: value }));
   }, 500);

   const navigate = useNavigate();

   const handleChange = (event) => {
      const value = event.target.value.toString();
      if (topUpMenu === 'Amount') {
         setAmount(value);
         getUnits(value);
      } else {
         setUnits(value);
         getAmount(value);
      }
   };

   const handleSubmit = (event) => {
      event.preventDefault();
      const total =
         topUpMenu === 'Amount'
            ? { amount: amount }
            : { amount: selector.amountConv.toString() };

      if (Number(total.amount) < 1000) {
         toast.error('The minimum top up is N1000');
         return;
      }

      dispatch(amountPayable(total));
      navigate('/top-up/payment');
   };

   const check = (e) => setEnabled(e.target.value);

   return (
      <div>
         <DialogWrapper
            open={openForm}
            maxWidth="sm"
            fullWidth
            onClose={closeForm}
         >
            <Box
               sx={{
                  mt: 2,
                  position: 'relative',
                  mx: '18px',
               }}
            >
               <Box
                  sx={{
                     height: '5px',
                     width: '100px',
                     borderRadius: '5px',
                     mx: 'auto',
                     bgcolor: '#374da0',
                  }}
               ></Box>
               <FaTimes
                  size={20}
                  style={{
                     position: 'absolute',
                     right: 0,
                     top: 0,
                     cursor: 'pointer',
                  }}
                  onClick={() => closeForm()}
               />
            </Box>
            <Typography
               color="#0F0E0E"
               fontSize={18}
               fontWeight={600}
               align="center"
               mt={3}
               sx={{
                  fontFamily: "'Raleway', 'Montserrat', sans-serif ",
               }}
            >
               Buy SMS Unit
            </Typography>

            <Typography
               align="center"
               fontSize={16}
               color="#878787"
               mb={2}
               sx={{
                  fontFamily: "'Raleway', 'Montserrat', sans-serif ",
               }}
            >
               How much would you like to fund your account with?
            </Typography>
            <FormControl
               style={{
                  width: '100%',
                  maxWidth: '150px',
                  border: 'none',
                  marginLeft: '20px',
                  marginTop: '10px',
                  fontFamily: "'Raleway', 'Montserrat', sans-serif ",
               }}
            >
               <Select
                  sx={{
                     mb: '1rem',
                     fontFamily: "'Raleway', 'Montserrat', sans-serif ",
                  }}
                  value={topUpMenu}
                  onChange={handleSelect}
                  displayEmpty
               >
                  <MenuItem
                     value="Amount"
                     sx={{
                        fontFamily: "'Raleway', 'Montserrat', sans-serif ",
                     }}
                  >
                     <Typography
                        fontSize={15}
                        sx={{
                           fontFamily: "'Raleway', 'Montserrat', sans-serif ",
                        }}
                     >
                        Enter Amount
                     </Typography>
                  </MenuItem>
                  <MenuItem
                     value="SMS Unit"
                     sx={{
                        fontFamily: "'Raleway', 'Montserrat', sans-serif ",
                     }}
                  >
                     <Typography
                        fontSize={15}
                        sx={{
                           fontFamily: "'Raleway', 'Montserrat', sans-serif ",
                        }}
                     >
                        Enter SMS Unit
                     </Typography>
                  </MenuItem>
               </Select>
            </FormControl>

            <form onSubmit={handleSubmit}>
               <Box mb={4} display="flex" flexDirection="column" px={2}>
                  <InputLabel
                     align="left"
                     htmlFor="val"
                     margin="dense"
                     sx={{
                        fontFamily: "'Raleway', 'Montserrat', sans-serif ",
                     }}
                  >
                     {topUpMenu === 'Amount' ? `${topUpMenu} ₦` : topUpMenu}
                  </InputLabel>
                  <TextInput
                     id="val"
                     fullWidth
                     margin="normal"
                     name={topUpMenu}
                     // onBlur={handleBlur}
                     onChange={handleChange}
                     type="number"
                     value={topUpMenu === 'Amount' ? amount : units}
                     variant="outlined"
                     InputProps={{
                        sx: {
                           fontFamily: "'Raleway', 'Montserrat', sans-serif ",
                           mb: 2,
                        },
                        endAdornment: (
                           <InputAdornment position="end">
                              <IconButton
                                 edge="end"
                                 onClick={() => {
                                    setAmount('');
                                    setUnits('');
                                 }}
                              >
                                 <HighlightOffIcon />
                              </IconButton>
                           </InputAdornment>
                        ),
                     }}
                  />
                  <InputLabel align="left" htmlFor="trans" margin="dense">
                     {topUpMenu === 'SMS Unit' ? 'Amount ₦' : 'SMS Units'}
                  </InputLabel>
                  <TextInput
                     fullWidth
                     margin="dense"
                     id="trans"
                     name="result"
                     // onBlur={handleBlur}
                     onChange={check}
                     type="text"
                     value={
                        topUpMenu === 'Amount' && amount !== ''
                           ? selector.unitsConv
                           : topUpMenu === 'SMS Unit' && units !== ''
                           ? selector.amountConv
                           : '0'
                     }
                     variant="outlined"
                     disabled
                  />

                  <Button
                     disabled={units === '' && amount === ''}
                     sx={{
                        borderRadius: '11px',
                        py: '10px',
                        // px: '35px',
                        fontSize: '15px',
                        width: '50%',
                        mx: 'auto',
                        mt: '40px',

                        fontFamily: "'Raleway', 'Montserrat', sans-serif ",
                     }}
                     variant="contained"
                     size="small"
                     type="submit"
                  >
                     Confirm
                  </Button>
                  <br />
               </Box>
            </form>
         </DialogWrapper>
      </div>
   );
};

export default TopUpForm;
