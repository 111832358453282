import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
   Box,
   Button,
   TextField,
   Typography,
   CircularProgress,
   IconButton,
   FormControlLabel,
   Checkbox,
   InputAdornment,
   styled,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LockIcon from '@mui/icons-material/Lock';
import NigeriaFlag from '../../../../assets/NigeriaFlag.svg';
// import GoogleIcons from '../../../../assets/GoogleIcons.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthSelector } from '../../../utils/selectors/selectors';
import { userloginPhone } from '../../../app/features/auth/AuthSlice';
import { Link } from 'react-router-dom';
import { resetLoading } from '../../../app/features/auth/AuthSlice';
import { useNavigate } from 'react-router-dom';

const PhoneNoLogin = () => {
   const TextInput = styled(TextField)(
      ({ theme }) => `
       color: #49454F;
       &.placeholer{
        color: red;
       }
     `
   );

   const boxSX = {
      '&.Mui-checked': {
         color: '#374DA0',
      },
   };

   // const GoogleLogin = styled(Button)(
   //    ({ theme }) => `
   //  border-radius: 10px;
   //  padding: 3px 16px;
   //  font-size: 0.8125rem;
   //  background: transparent;
   //  border: 1px solid #374DA0;
   //  color: #374DA0;
   //  width: 45%;
   //  margin: 0 auto;
   //  margin-top: 15px;

   //  &:hover {
   //  background: #F7F7F8;
   //  }
   //  `
   // );

   const [showPassword, setShowPassword] = React.useState(false);
   const [stayLoggedIn, setStayLoggedIn] = React.useState(true);
   const navigate = useNavigate();

   const handleClickShowPassword = () => setShowPassword((show) => !show);

   const handleMouseDownPassword = (event) => {
      event.preventDefault();
   };

   const dispatch = useDispatch();
   const selector = useSelector(getAuthSelector);

   useEffect(() => {
      dispatch(resetLoading());
      if (selector.isLoggedIn === true) {
         navigate('/dashboard');
      }
      // eslint-disable-next-line
   }, [selector.isLoggedIn]);

   useEffect(() => {
      if (selector.error === 'Please complete verification step') {
         navigate('/account-verification');
      }
      // eslint-disable-next-line
   }, [selector.error]);

   return (
      <div>
         <Formik
            initialValues={{
               phoneNumber: '',
               password: '',
               email: null,
            }}
            validationSchema={Yup.object().shape({
               phoneNumber: Yup.string()
                  .max(255)
                  .required('The phone number field is required'),
               password: Yup.string()
                  .max(255)
                  .required('Please enter your password'),
            })}
            onSubmit={async (
               values,
               { setErrors, setStatus, setSubmitting }
            ) => {
               // dispatch login form
               dispatch(userloginPhone({ ...values, stayLoggedIn }));
            }}
         >
            {({
               errors,
               handleBlur,
               handleChange,
               handleSubmit,
               isSubmitting,
               touched,
               values,
               setFieldValue,
            }) => (
               <form noValidate onSubmit={handleSubmit}>
                  <Box
                     sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: 2,
                     }}
                  >
                     <TextInput
                        fullWidth
                        margin="normal"
                        helperText={touched.phoneNumber && errors.phoneNumber}
                        name="phoneNumber"
                        placeholder="Phone number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.phoneNumber}
                        variant="outlined"
                        InputProps={{
                           startAdornment: (
                              <InputAdornment position="start">
                                 <img
                                    src={NigeriaFlag}
                                    alt="flag"
                                    className="nigeriaFlag"
                                 />
                                 <Typography ml={-1}> </Typography>
                              </InputAdornment>
                           ),
                           endAdornment: (
                              <InputAdornment position="end">
                                 <IconButton
                                    edge="end"
                                    onClick={() => {
                                       setFieldValue('phoneNumber', '');
                                    }}
                                 >
                                    <HighlightOffIcon />
                                 </IconButton>
                              </InputAdornment>
                           ),
                        }}
                     />
                     <TextInput
                        fullWidth
                        margin="normal"
                        helperText={touched.password && errors.password}
                        name="password"
                        placeholder="Enter password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type={showPassword ? 'text' : 'password'}
                        value={values.password}
                        variant="outlined"
                        InputProps={{
                           startAdornment: (
                              <InputAdornment position="start">
                                 <LockIcon />
                              </InputAdornment>
                           ),
                           endAdornment: (
                              <InputAdornment position="end">
                                 <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                 >
                                    {showPassword ? (
                                       <VisibilityOff />
                                    ) : (
                                       <Visibility />
                                    )}
                                 </IconButton>
                              </InputAdornment>
                           ),
                        }}
                     />
                  </Box>
                  <Box
                     alignItems="center"
                     display="flex"
                     justifyContent="space-between"
                  >
                     <FormControlLabel
                        control={
                           <Checkbox
                              checked={stayLoggedIn}
                              onChange={(e) => {
                                 setStayLoggedIn(e.target.checked);
                              }}
                              sx={boxSX}
                           />
                        }
                        label={
                           <>
                              <Typography
                                 variant="body2"
                                 fontFamily="'Raleway', 'Montserrat', sans-serif"
                                 color="#0F0E0E"
                                 fontWeight={300}
                              >
                                 Keep me logged in
                              </Typography>
                           </>
                        }
                     />
                     <Link to="/forgotpassword" className="termsLink">
                        <b>Forgot Password?</b>
                     </Link>
                  </Box>
                  <Button
                     sx={{
                        borderRadius: '11px',
                        py: '7px',
                        fontSize: '15px',
                        width: '50%',
                        mx: 'auto',
                        mt: '20px',
                        '@media (max-width: 600px)': {
                           width: '80%',
                        },
                     }}
                     type="submit"
                     variant="contained"
                     size="small"
                     startIcon={
                        selector.loading === 'pending' ? (
                           <CircularProgress size="1rem" color="primary" />
                        ) : null
                     }
                     disabled={
                        !values.phoneNumber ||
                        !values.password ||
                        selector.loading === 'pending'
                     }
                  >
                     Log in
                  </Button>
                  <br />
               </form>
            )}
         </Formik>
         <Typography mt={2}>
            Don't have an account?{' '}
            <Link to="/signup" className="termsLink">
               Sign up
            </Link>
         </Typography>
      </div>
   );
};

export default PhoneNoLogin;
